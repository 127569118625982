import { copyClipboard } from "../../../common/utils";

export const UpdatingPage = () => {
    const codeText = 'Set-ExecutionPolicy -ExecutionPolicy Unrestricted -Scope Process -Force\n& "C:\\Program Files (x86)\\Amazon\\SiteConnect\\configure-siteconnect.ps1" update'

    return (
    <body>
        <h2>Updating The SiteConnect Web Server</h2>
        <p>You may be notified that you must update the SiteConnect software to continue
        using the VPN.</p>
        
        <p>
        To update, simply run the following command in PowerShell as an Administrator:
            <pre><code className="code-block">{codeText}</code></pre>
            <button className="copy-clipboard" type="button" onClick={copyClipboard}>Copy Code</button>
        </p>
    </body>
    )
}