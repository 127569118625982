import React from "react";
import "../../../css/sakura-ink.css";
import "../../../css/cards.css";
import { Link } from "react-router-dom";
import { copyClipboard } from "../../../common/utils";

export const InstallPage = () => {
    const codeText = 'Set-ExecutionPolicy -ExecutionPolicy Unrestricted -Scope Process -Force\n& "C:\\Program Files (x86)\\Amazon\\SiteConnect\\configure-siteconnect.ps1" install'

    return (
        <body>
        <h1>Windows Install</h1>
        <p>
            SiteConnect requires the installation of a web server component to allow
            you to successfully connect to a site.
        </p>
        ⚠️ You must have administrative access to the machine to complete the
        installation.
        <h2 id="install-aws-vpn-client">AWS VPN Client</h2>
        <ol>
            <li>
            Download the{" "}
            <a href="https://d20adtppz83p9s.cloudfront.net/WPF/latest/AWS_VPN_Client.msi">
                AWS VPN Client
            </a>
            </li>
            <li>Install the MSI file</li>
            <img
            src={require("../../../images/windows/install-aws-vpn-client.png")}
            alt="Install AWS VPN Client"
            />

            <ul>
            <li>
                Accept the license agreement, and click <code>Install</code>
            </li>
            <img
                src={require("../../../images/windows/install-aws-vpn-client-license-agreement.png")}
                alt="Agree To License"
            />

            <li>
                Click <code>Finish</code>
            </li>
            <img
                src={require("../../../images/windows/install-aws-vpn-client-finish.png")}
                alt="AWS VPN Client Install Finished"
            />
            </ul>
        </ol>
        <h2 id="install-siteconnect-web-server">SiteConnect Web Server</h2>
        <ol>
            <li>
            Download the{" "}
            <a href="https://downloads.siteconnect.opstech.a2z.com/latest/WINDOWS/siteconnect-web-server.msi">
                SiteConnect Web Server
            </a>
            </li>
            <li>Install the MSI file</li>
            <img
            src={require("../../../images/windows/install-siteconnect-web-server.png")}
            alt="Install SiteConnect Web Server"
            />
            <ul>
            <li>
                Click <code>Next</code>
            </li>
            <img
                src={require("../../../images/windows/install-siteconnect-web-server-destination-directory.png")}
                alt="Install Destination Directory"
            />

            <li>
                Click <code>Finish</code>
            </li>
            <img
                src={require("../../../images/windows/install-siteconnect-web-server-finish.png")}
                alt="SiteConnect Install Finished"
            />
            </ul>
            <li>Run PowerShell as an administrator</li>
            <img
            src={require("../../../images/windows/run-powershell-as-administrator.png")}
            alt="Run PowerShell As Administrator"
            />
            <li>
            Copy &amp; Paste the following command in PowerShell to execute the
            configuration script:
            </li>
            <pre>
            <code className="code-block">
                {codeText}
            </code>
            </pre>
            <button className="copy-clipboard" type="button" onClick={copyClipboard}>
            Copy Code
            </button>
            <br />
            If you see the text{" "}
            <code>SiteConnect has been successfully installed</code>, then the
            installation is complete.
        </ol>
        <h2>Next Steps</h2>
        You will now be able to successfully{" "}
        <Link to="/windows/vpn-connect.html">connect to the VPN</Link>.
        </body>
    );
};
