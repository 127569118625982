import { Link } from "react-router-dom"

export const PrerequisitesPage = () => {
    return (
    <body>
    <h1>Connection Prerequisites</h1>
    <p>
        To connect to a VPN, you must have the following:
        <ul>
            <li>For third party vendors:
                <ul>
                    <li>You must have an email address registered with IdPrism. Work with your Amazon point of contact to set this up.</li>
                    <li>Supported <Link to="/windows/troubleshooting.html#supported-os">OS version</Link></li>
                    <li>Enabled Firewall</li>
                    <li>Enabled Antivirus</li>
                    <li>AWS VPN Client</li>
                    <li>SiteConnect Web Server <Link to="/windows/install.html">Installed</Link></li>
                </ul>
            </li>
            <li>VPN Endpoint URL</li>
            <li>OVPN Configuration File</li>
        </ul>
    </p>

    <h2>IdPrism Account</h2>
    <p>SiteConnect uses IdPrism as a central identity store for third party vendors. All non-amazon users must be registered in IdPrism before using SiteConnect. 
        You can login and verify your account here: <Link to="https://portal-na.idprism-auth.amazon.dev" target="_blank">https://portal-na.idprism-auth.amazon.dev</Link></p>

    <h2>VPN Endpoint URL</h2>
    <p>
        Once you have been granted access by your Amazon point of contact, they will provide you with a URL. 
        This URL is where you will authenticate and download the OVPN configuration file.

    </p>

    <h2>OVPN Configuration File</h2>
    <p>
    The OVPN configuration file has an <code>.ovpn</code>
    file extension, and is also referred to as  
    the <b>profile</b> when using the AWS VPN Client.

    You will need a separate OVPN file for <b>each</b> site you
    attempt to connect to.

    You must work with your Amazon point of contact to download your OVPN file.
    </p>
    </body>
    )
}