import * as React from "react";
import Modal from "@amzn/awsui-components-react/polaris/modal";
import Box from "@amzn/awsui-components-react/polaris/box";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Button from "@amzn/awsui-components-react/polaris/button";
import { returnPosturePayload } from "@amzn/siteconnect-idp-posture-assessment/dist/lib/network";
import { useState, useEffect } from "react";
import { PostureResult } from "../../../common/models";
import { Alert, Input } from "@amzn/awsui-components-react";
import { getPostureResult } from "../../../common/utils"
import { Link } from "react-router-dom";


interface PostureModalProps {
  setVisible: (visible: boolean) => void;
  visible: boolean;
}

const LOADING = "Do not exit the page. Posture status is being verified...";
const PASSED_POSTURE =
  "You have succeeded the posture check! You may exit out of the screen now.";
const FAILED_POSTURE =
  "You have failed the posture check for the following reason(s). Please fix the issue and try again: ";


export const PostureModal = ({ setVisible, visible }: PostureModalProps) => {
  // There is a super annoying uncaught runtime error exception that often triggers when activating the modal in development mode:
  // ResizeObserver loop completed with undelivered notifications. This only occurs in development mode and afaik it
  // is a benign error https://cloudscape.aws.dev/versions/from-v21-to-v30/known-migration-issues/#client-side-errors-due-to-resizeobserver.
  // So just ignore the error if rendering in dev mode :)

  const [postureResult, setPostureResult] = useState<PostureResult>();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [userId, setUserId] = useState<string>();

  useEffect(() => {
    const getPayload = async () => {
      const payload = JSON.stringify(await returnPosturePayload());
      getPostureResult(payload, userId).then(r => {      
        setPostureResult(r.data)
      }).catch(err => {
        setPostureResult({ result: false, reason: err.response.data });
      }).finally(() =>
        setLoading(false)
      )
    };
    if (loading) {
      getPayload(); 
    }
  }, [userId, loading])
 

  const getModalText = (result: boolean, loading: boolean) => {
    if (loading) {
      return LOADING;
    } else {
      if (result) {
        return PASSED_POSTURE;
      } else {
        var errorComponent;
        if (postureResult?.reason?.includes('Missing SiteConnect Web Server')) {
          errorComponent = <><b>Please fix your local web server. If the web server is not installed, follow the instructions <Link to={"/windows/install.html#install-siteconnect-web-server"}>in the install page</Link>.
            If the web server is already installed, follow the instructions <Link to={"/windows/updating.html"}>in the updating page</Link>.</b></>
        } else {
          errorComponent = <b>{postureResult?.reason}</b>
        }
        return (
          <>
            <>{FAILED_POSTURE}</>
            {errorComponent}
          </>
        );
      }
    }
  };

  useEffect(() => {
    if (visible) {
      setLoading(true);
    }
  }, [visible]);

  return (
    <Modal
      onDismiss={() => setVisible(false)}
      visible={visible}
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button
              variant="primary"
              onClick={() => setLoading(true)}
              loading={loading}
            >
              Check Again
            </Button>
          </SpaceBetween>
        </Box>
      }
      header="Posture Checker"
      data-testid="PostureModal"
    >
      <SpaceBetween direction="vertical" size="xs">
        <>{getModalText(postureResult?.result!, loading)}</>
        {!loading && !postureResult?.result! && (
          <>
            <Alert type="info">
              If you need help debugging posture issues, you may enter your
              email before checking posture to help our team identify your
              posture. Note that this is an optional field and you are not
              required to enter your email to check posture.
            </Alert>
            <Input
              value={userId ?? ""}
              placeholder="Email Address"
              onChange={({ detail }) => {
                setUserId(detail.value);
              }}
              data-id="linkInputField"
            />
          </>
        )}
      </SpaceBetween>
    </Modal>
  );
};
