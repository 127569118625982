import { Link } from "react-router-dom"

export const TroubleshootingPage = () => {
    const codeText = 'Set-ExecutionPolicy -ExecutionPolicy Unrestricted -Scope Process -Force\n& "C:\\Program Files (x86)\\Amazon\\SiteConnect\\configure-siteconnect.ps1" install'

    return (
    <body>
        <h1>Windows Troubleshooting</h1>

        <h2>SiteConnect Connection Issues</h2>
        This could be caused by a number of factors:
        <ul>
            <li id="supported-os"><b>Noncompliant operating system version</b></li>

            Ensure your Operating System and version are supported:

            <table>
                <thead>
                    <th>Operating System</th>
                    <th>Version</th>
                    <th>Edition</th>
                    <th>Supported until</th>
                </thead>
                <tbody>
                    <tr>
                        <td rowSpan={5}>Windows 10</td>
                        <td rowSpan={1}>1809</td>
                        <td>Enterprise LTSC</td>
                        <td>January 9, 2029</td>
                    </tr>
                    <tr>
                        <td rowSpan={2}>21H2</td>
                        <td>Enterprise</td>
                        <td>June 11, 2024</td>
                    </tr>
                    <tr>
                        <td>Enterprise LTSC</td>
                        <td>January 12, 2027</td>
                    </tr>
                    <tr>
                        <td rowSpan={2}>22H2</td>
                        <td>Professional</td>
                        <td rowSpan={2}>October 14, 2025</td>
                    </tr>
                    <tr>
                        <td>Enterprise</td>
                    </tr>
                    <tr>
                        <td rowSpan={7}>Windows 11</td>
                        <td rowSpan={1}>21H2</td>
                        <td>Enterprise</td>
                        <td>October 8, 2024</td>
                    </tr>
                    <tr>
                        <td rowSpan={3}>22H2</td>
                        <td>Home</td>
                        <td rowSpan={2}>October 8, 2024</td>
                    </tr>
                    <tr>
                        <td>Professional</td>
                    </tr>
                    <tr>
                        <td>Enterprise</td>
                        <td>October 14, 2025</td>
                    </tr>
                    <tr>
                        <td rowSpan={3}>23H2</td>
                        <td>Home</td>
                        <td rowSpan={2}>November 11, 2025</td>
                    </tr>
                    <tr>
                        <td>Professional</td>
                    </tr>
                    <tr>
                        <td>Enterprise</td>
                        <td>November 10, 2026</td>
                    </tr>
                </tbody>
            </table>

            <li id="os-edition-not-allowed"><b>This operating system edition is not allowed</b></li>

            SiteConnect will only allow Windows editions to connect which currently recieve support and security updates.

            <li><b>A firewall must be enabled</b></li>

            SiteConnect will detect the included OS firewall and most other firewall products which Windows is able to detect.<br/>
            At least one firewall must be detected and enabled.

            <li><b>At least one antivirus software must be enabled</b></li>

            SiteConnect will detect the included OS antivirus and most other antivirus products which Windows is able to detect.<br/>
            At least one antivirus must be detected and enabled.

            <li><b>SiteConnect Web Server Not Configured</b></li>
            <p>
            If you have followed the <Link to="/windows/install.html">Windows Install</Link> then
            you should be able to open&nbsp;
            <Link to="http://127.0.0.1:32182" target="_blank" rel="noopener noreferrer">http://127.0.0.1:32182</Link>
            &nbsp;and view the posture payload that is returned to SiteConnect. If the browser refuses to connect, then
            the web server is <b>not</b> installed correctly.
            </p>

            <p>
            The easiest solution is to just re-run the install script in PowerShell as an Administrator
            which will correctly configure your system:
            <pre><code className="code-block">{codeText}</code></pre>
            <button className="copy-clipboard" type="button">Copy Code</button>
            <br/>

            If this script fails then you must send the following log file to your
            Amazon representative for further troubleshooting:
            <code>%USERPROFILE%\AppData\Local\SiteConnect\configure-siteconnect.log</code>
            </p>

            <li id="user-not-authorized"><b>User Not Authorized</b></li>
            <p>
                If you&apos;ve validated that you have all necessary components installed:
                <ol>
                    <li>AWS VPN Client</li>
                    <li>SiteConnect Web Server</li>
                </ol>
                and you are still rejected during a VPN connection attempt, then you are either using
                expired credentials, or an invalid OVPN file.
                Contact your Amazon representative for an updated pair of credentials and OVPN file.

                <br/>
                ⚠️ <b>The credentials expire 15 minutes after creation, and last 8 hours after initial use.</b>
            </p>
        </ul>

        <h2>AWS VPN Client Connection Issues</h2>
        Please ensure you are using the latest version of the AWS VPN Client. See&nbsp; 
        <Link to="https://docs.aws.amazon.com/vpn/latest/clientvpn-user/client-vpn-connect-windows.html#client-vpn-connect-windows-release-notes">
            AWS VPN Client Release Notes.
        </Link>

        <br/>

        See the&nbsp;
        <Link to="https://docs.aws.amazon.com/vpn/latest/clientvpn-user/windows-troubleshooting.html">
            AWS VPN Client Windows Troubleshooting docs
        </Link>
        &nbsp;for specific problems related to the AWS VPN Client.
    </body>
    )
}